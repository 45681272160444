<template>
    <div class="body">
        <div class="main">
            <van-cell-group>
                <van-field 
                    v-model="temp.username"
                    label="活动主题" 
                    required
                    input-align="right"
                    placeholder="请输入用户名"
                    :error-message="name_error"
                />
                <!--  -->
                <van-field
                    :value="temp.start_time"
                    label="开始时间"
                    required
                    placeholder="选择结束时间"
                    @click="show_start = true"
                    input-align="right"
                />
                <van-calendar v-model="show_start" @confirm="start_time" />
                <!--  -->
                <van-field
                    :value="temp.end_time"
                    label="结束时间"
                    required
                    placeholder="选择结束时间"
                    @click="show_end = true"
                    input-align="right"
                />
                <van-calendar v-model="show_end" @confirm="end_time" />
                <!--  -->
                <van-field
                    v-model="temp.text"
                    required
                    clearable
                    size="large"
                    rows="2"
                    autosize
                    label="活动介绍"
                    type="textarea"
                    maxlength="500"
                    placeholder="请输入......"
                    show-word-limit
                />                
            </van-cell-group>
        </div>
        <div class="content">
            <p>添加照片/视频</p>
            <div>
                <van-uploader v-model="fileList" multiple image-fit="cover" max-count="3"
                accept="image/jpeg, video/mp4" :after-read="afterRead"/>
            </div>
        </div>
        <van-collapse v-model="activeNames" style="margin-top: 1rem">
            <van-collapse-item title="选择班级" name="2" icon="friends-o">
                <van-checkbox-group v-model="temp.checkbox" direction="horizontal">
                    <van-checkbox name="1">复选框 1</van-checkbox>
                    <van-checkbox name="2">复选框 2</van-checkbox>
                </van-checkbox-group>
            </van-collapse-item>
        </van-collapse>
        <div>
            <img src="@/assets/img/button_submit@2x.png" class="imgbtn" @click="subimt">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                username: '',
                password: '',
                value: '',
                show_start: false,
                show_end: false,
                activeNames: ['2'],
                temp: {
                    username: '',
                    start_time: '',
                    end_tiem: '',
                    text: '',
                    checkbox: [],
                },
                name_error: '',
                fileList: [
                ],
            };
        },
        created() {
        },
        methods: {
            onSubmit(values) {
              console.log('submit', values);
            },
            start_time(date) {
                console.log('??');
                this.temp.start_time = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                this.show_start = false;
            },
            end_time(date) {
                console.log('---');
                this.temp.end_time = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                this.show_end = false;
            },
            subimt() {

            },
            afterRead(file){
                console.log(file);
            }
        },
    }
</script>

<style lang="less" scoped>
.body{
    padding: 1rem;
}
.main{
    box-shadow:0 0 10px #dadada;
    padding: .5rem;
}
/deep/ .van-field__control{
    /* text-align: right; */
    font-size: 1rem;
}
.van-cell{
    border: none;
    margin-bottom: 1rem;
}
/deep/ .van-field__label{
    color: rgb(0, 0, 0);
    font-size: 1rem;
    font-weight: bold;
}
/deep/ .van-cell--required::before {
    position: absolute;
    left: 1%;
    top: 1rem;
    color: #ee0a24;
    font-size: 1.5rem;
    content: '*';
}
.content{
    margin-top: 1rem;

    p{
        font-weight: bold;
    }
}
/deep/ .van-uploader__upload-icon{
    color: white;
}
/deep/ .van-uploader__upload{
    background-color: rgb(200, 200, 200);
    width: 6rem;
    height: 6rem;
}
/deep/ .van-uploader__preview-image{
    width: 6rem;
    height: 6rem;
}
/deep/ .van-icon-friends-o:before{
    font-size: 1.5rem;
    margin-right: .5rem;
}
/deep/ .van-cell--clickable{
    font-size: 1rem;
    font-weight: bold;
}
/deep/ .van-checkbox-group--horizontal{
    display: block;
}
/deep/ .van-checkbox--horizontal{
    font-size: 1rem;
    margin-bottom: 1rem;
}
.imgbtn{
    width: 100%;
    margin-top: 1rem;
}
</style>